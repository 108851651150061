import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import OverviewSection from "../components/OverviewSection";
import TestimonialsSection from "../components/TestimonialsSection";
import TeamMember from "../components/TeamMember";
import Footer from "../components/Footer";
import MobileLayout from "../components/MobileLayout";
import TeamMemberHomeLayout from "../components/TeamMemberHomeLayout";
import TestimonialsCardSection from "../components/TestimonialsCardSection";
import EventsSection from "../components/EventsSection";
import EventsSection2 from "../components/EventsSection2";
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [headerActivePage, setHeaderActivePage] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    // Fetch the team members from the JSON file in the public folder
    fetch("/fileData/teamData.json")
      .then((response) => response.json())
      .then((data) => setTeamMembers(data.team))
      .catch((error) => console.error("Error fetching team members:", error));
  }, []);

  const handleNavigation = (page, route) => {
    setHeaderActivePage(page);
    navigate(route);
  };

  return (
    <div id="home">
      <Header handleNavigation={handleNavigation} activepage={"Home"} />
      <HeroSection handleNavigation={handleNavigation} />
      <MobileLayout />
      <EventsSection2 />
      <TeamMemberHomeLayout teamMembers={teamMembers} />
      <TestimonialsCardSection />
      <Footer />
    </div>
  );
};

export default HomePage;
