import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "../styles/components/EventSection2.module.css";
import { FaYoutube, FaLaptop, FaCalendarAlt } from "react-icons/fa";

const EventsSection2 = () => {
    const [events, setEvents] = useState([]);
    const [activeTab, setActiveTab] = useState("upcoming");
    const [searchQuery, setSearchQuery] = useState("");
    const [sortOrder, setSortOrder] = useState("date");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tagline, settagline] = useState();

 

    useEffect(() => {
        // Fetch data from the JSON file
        fetch("/fileData/eventsData.json")
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            setEvents(data.events);
            settagline(data.tagline);
            setLoading(false);
          })
          .catch((err) => {
            setError("Failed to load events.");
            setLoading(false);
          });
      }, []);
      

    const filteredEvents = events
        .filter((event) => activeTab === "all" || event.status === activeTab)
        .filter((event) => event.title.toLowerCase().includes(searchQuery.toLowerCase()))
        .sort((a, b) => {
            if (sortOrder === "date") {
                return new Date(a.date) - new Date(b.date);
            }
            return a.type.localeCompare(b.type);
        });

    if (loading) {
        return <p>Loading events...</p>;
    }

    if (error) {
        return <p className={styles.errorMessage}>{error}</p>;
    }

    return (
        <>
            <div className={styles.eventsSection2}>
                <h2 className={styles.sectionTitle}>EVENTS</h2>
                <p className={styles.sectionDescription}>
                    {tagline}
                </p>
            </div>
            <div className={styles.eventsSection}>
                <div className={styles.leftPanel}>
                    {["past", "ongoing", "upcoming", "all"].map((tab) => (
                        <button
                            key={tab}
                            className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ""}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab.charAt(0).toUpperCase() + tab.slice(1)} Events
                        </button>
                    ))}
                </div>
                <div className={styles.rightPanel}>
                    <div className={styles.searchBar}>
                        <input
                            type="text"
                            className={styles.searchInput}
                            placeholder="Search events..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <select
                            className={styles.sortDropdown}
                            value={sortOrder}
                            onChange={(e) => setSortOrder(e.target.value)}
                        >
                            <option value="date">Sort by Date</option>
                            <option value="type">Sort by Type</option>
                        </select>
                    </div>
                    <div className={styles.horizontalScrollContainer}>
                        {filteredEvents.length > 0 ? (
                            filteredEvents.map((event) => (
                                <div className={styles.eventCard} key={event.id}>
                                    <div className={styles.iconContainer}>
                                        {event.type === "youtube" && <FaYoutube className={`${styles.icon} ${styles.youtube}`} />}
                                        {event.type === "webinar" && <FaLaptop className={`${styles.icon} ${styles.webinar}`} />}
                                        {event.type === "workshop" && <FaCalendarAlt className={`${styles.icon} ${styles.workshop}`} />}
                                    </div>
                                    <h3 className={styles.eventTitle}>{event.title}</h3>
                                    <p className={styles.eventDate}>{new Date(event.date).toLocaleString()}</p>
                                    <p className={styles.eventType}>{event.type.toUpperCase()}</p>

                                    <button
                                        className={styles.actionButton}
                                        onClick={() => window.location.href = event.link}
                                    >
                                        {event.status === "upcoming"
                                            ? "Register"
                                            : event.status === "ongoing"
                                                ? "Join Now"
                                                : "View Details"}
                                    </button>


                                </div>
                            ))
                        ) : (
                            <p className={styles.noEventsMessage}>No events found for this category.</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventsSection2;
