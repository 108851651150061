import React, { useEffect, useState } from "react";
import "../styles/components/Footer.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Footer = () => {
  const [contactInfo, setContactInfo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch contact info from the JSON file
    axios
      .get("/fileData/footerData.json")
      .then((response) => {
        setContactInfo(response.data);
      })
      .catch((error) => {
        console.error("Error fetching contact info:", error);
      });
  }, []);

  const handleNavigation = (page, route) => {
    navigate(route);
  };

  if (!contactInfo) {
    return <div>Loading...</div>;
  }

  return (
    <footer className="footer" role="contentinfo">
      <div className="footer-container">
        <div className="footer-column">
          <h3>Quick Links</h3>
          <ul>
            <li onClick={() => handleNavigation("Home", "/Home")} key="home">
              <a href="#home">Home</a>
            </li>
            <li onClick={() => handleNavigation("service", "/service")} key="services">
              <a href="#services">Services</a>
            </li>
            <li onClick={() => handleNavigation("About", "/About")} key="aboutus">
              <a href="#aboutus">About Us</a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Contact Info</h3>
          <p>Email: <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a></p>
          <p>Phone: <a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a></p>
        </div>
        <div className="footer-column">
          <h3>Follow Us</h3>
          <div className="social-icons">
            {contactInfo.socialLinks.map(({ platform, link }) => (
              <a
                key={platform}
                href={link}
                aria-label={`Visit our ${platform} page`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={`fab fa-${platform.toLowerCase()}`}></i>
              </a>
            ))}
          </div>
        </div>
        <div className="footer-column">
          <h3>Subscribe to Updates</h3>
          <form className="subscribe-form" action="/subscribe.php" method="post">
            <input name="email" type="email" placeholder="Enter your email" required /><br />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Vittapath. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
