import React, { useState, useEffect } from "react";
import '../styles/pages/TestimonialPage.css';
import { FaQuoteLeft } from 'react-icons/fa';
import Header from '../components/Header';
import Footer from '../components/Footer';
const TestimonialCard = ({ avatar, name, role, feedback, bgColor, textColor }) => {
    return (
        <div className="testimonial-card" style={{ backgroundColor: bgColor, color: textColor }}>
            <FaQuoteLeft className="quote-icon" />
            <p className="feedback">"{feedback}"</p>
            <div className="client-info">
                <img src={avatar} alt={`${name}'s avatar`} className="avatar" />
                <h3 className="client-name">{name}</h3>
                <p className="role">{role}</p>
            </div>
        </div>
    );
};

const TestimonialPage = () => {
    const testimonials = [
        {
            avatar: 'https://via.placeholder.com/100',
            name: 'John Doe',
            role: 'Software Engineer',
            feedback: 'This platform transformed my learning experience! Interactive and user-friendly design kept me engaged.',
            bgColor: '#283593', // Dark Blue
            textColor: '#ffffff',
        },
        {
            avatar: 'https://via.placeholder.com/100',
            name: 'Jane Smith',
            role: 'Data Scientist',
            feedback: 'The personalized coaching and advanced analytics helped me excel in my career. Highly recommended!',
            bgColor: '#1565c0', // Medium Blue
            textColor: '#ffffff',
        },
        {
            avatar: 'https://via.placeholder.com/100',
            name: 'Michael Johnson',
            role: 'Product Manager',
            feedback: 'The progress tracking and goal setting are unmatched! This platform is a game changer.',
            bgColor: '#b0bec5', // Light Grey-Blue
            textColor: '#ffffff',
        },
        {
            avatar: 'https://via.placeholder.com/100',
            name: 'Emily Davis',
            role: 'Graphic Designer',
            feedback: 'I loved the intuitive UI and the ability to customize my learning path. A must-try platform!',
            bgColor: '#5c6bc0', // Light Blue (Teal-Blue)
            textColor: '#ffffff',
        },
        {
            avatar: 'https://via.placeholder.com/100',
            name: 'Chris Martin',
            role: 'Business Analyst',
            feedback: 'The ability to adjust content complexity and word length made studying a breeze. Fantastic tool!',
            bgColor: '#64b5f6', // Light Blue
            textColor: '#ffffff',
        },
        {
            avatar: 'https://via.placeholder.com/100',
            name: 'Sophia Lee',
            role: 'Entrepreneur',
            feedback: 'The platform’s search functionality and topic-specific quizzes are perfect for focused learning!',
            bgColor: '#1e88e5', // Medium Blue
            textColor: '#ffffff',
        },
        {
            avatar: 'https://via.placeholder.com/100',
            name: 'Daniel Garcia',
            role: 'Student',
            feedback: 'A great platform for students! The interactive tests and progress tracking kept me motivated.',
            bgColor: '#42a5f5', // Light Blue (Sky)
            textColor: '#ffffff',
        },
        {
            avatar: 'https://via.placeholder.com/100',
            name: 'Ava Thompson',
            role: 'Lecturer',
            feedback: 'As an educator, I appreciate the detailed analytics and ability to monitor student progress.',
            bgColor: '#90a4ae', // Muted Grey-Blue
            textColor: '#ffffff',
        },
        {
            avatar: 'https://via.placeholder.com/100',
            name: 'David Williams',
            role: 'Marketing Specialist',
            feedback: 'The customization options allowed me to tailor my learning experience to fit my needs perfectly.',
            bgColor: '#8e99f3', // Light Lavender Blue
            textColor: '#ffffff',
        }
    ];
         
        
    

    return (
        <>
       <Header activepage={"Testimonials"} />

            <div className="testimonial-page">
                <h2 className="page-title">What Our Users Say</h2>
                <p className="page-description">
                    Discover how our platform is making a difference in the lives of our users. Their words, their experiences!
                </p>
                <div className="testimonial-cards">
                    {testimonials.map((testimonial, index) => (
                        <TestimonialCard
                            key={index}
                            avatar={testimonial.avatar}
                            name={testimonial.name}
                            role={testimonial.role}
                            feedback={testimonial.feedback}
                            bgColor={testimonial.bgColor}
                            textColor={testimonial.textColor}
                        />
                    ))}
                </div>
            </div>
        
        <Footer />
        </>
    );
};

export default TestimonialPage;
