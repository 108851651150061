import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/components/Header.css";

const Header = ({ activepage }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(activepage);
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const navigate = useNavigate();

  const handleNavigation = (page, route) => {
    setActiveTab(page);
    navigate(route);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <>
      <div className={`logo-container ${showHeader ? "visible" : "hidden"}`}>
        <img src="/photo/logo.png" alt="Logo" className="logo-image" />
      </div>

      <header
        className={`header sticky ${menuOpen ? "active" : ""} ${showHeader ? "visible" : "hidden"}`}
        role="banner"
      >
        <div className="container">
          <button
            className="menu-toggle"
            aria-label="Toggle navigation menu"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            ☰
          </button>

          <nav aria-label="Main Navigation">
            <ul className={`nav-links ${menuOpen ? "open" : ""}`}>
              {[
                { name: "Home", route: "/Home" },
                { name: "About", route: "/About" },
                { name: "Services", route: "/service" },
                { name: "Subscription", route: "/subscribe" },
                { name: "Contact Us", route: "/contact" },
              ].map(({ name, route }) => (
                <li
                  key={name}
                  onClick={() => handleNavigation(name, route)}
                  className={activeTab === name ? "active" : ""}
                >
                  <a className="menu-button-txt">{name}</a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
