import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage.js';
import ServicePage from './pages/ServicePage.js';
import SubscriptionPlanPage from './pages/SubscriptionPlanPage.js';
import TestimonialPage from './pages/TestimonialPage.js';
import AboutUs from "./pages/AboutUs.js";
//import TeamMembersPage from './pages/TeamMembersPage.js';
import ContactUsPage from './pages/ContactUsPage.js';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/subscribe" element={<SubscriptionPlanPage />} />
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="About" element={<AboutUs />} />
          <Route exact path="/Home" element={<HomePage />} />
          <Route exact path="/service" element={<ServicePage />} />
          <Route exact path="/testimonial" element={<TestimonialPage />} />
          {/*  <Route exact path="/team" element={<TeamMembersPage />} /> */}
          <Route exact path="/contact" element={<ContactUsPage />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
