import React, { useState, useEffect } from "react";
import styles from '../styles/pages/AboutUs.module.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from "axios";

const AboutUs = () => {

  const [content, setContent] = useState();




    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    
      // Fetch JSON data
      fetch("/fileData/aboutData.json")
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => setContent(data))
        .catch((error) => console.error("Error fetching content:", error));
    }, []);
    
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, []);
    

    if (!content) {
      return <div>Loading...</div>;
    }
  return (
    <>
      <Header activepage={"About"} />
      <div className={styles.container}>
        <header className={styles.hero}>
          <h1 className={styles.title}>{content.header.title}</h1>
          <p className={styles.subtitle}>
          {content.header.subtitle}
          </p>
        </header>

        <div className={styles.timeline}>
          

          {content.sections.map((section, index) => (
            <div key={index} className={styles.timelineItem}>
              <div className={styles.circle}></div>
              <div className={styles.timelineContent}>
                <h2 className={styles.timelineHeading}>{section.title}</h2>
                <p>{section.content}</p>
              </div>
            </div>
          ))}

        </div>

        <footer className={styles.footer}>
          <p>Join the Vittapath Trading Community today and turn your trading ambitions into reality.</p>
        </footer>
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;
