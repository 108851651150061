import React, { useState, useEffect } from "react";
import { FaTelegramPlane } from "react-icons/fa"; // Import Telegram icon
import { useNavigate } from "react-router-dom";
import "../styles/components/HeroSection.css";
import axios from 'axios';

const HeroSection = ({ handleNavigation }) => {
  const [taglines1, setTaglines1] = useState();
  const [taglines2, setTaglines2] = useState();
  const [telegramLink, setTelegramLink] = useState();



  useEffect(() => {
    // Fetch data from JSON file
    fetch('fileData/hometopSectionData.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setTaglines1(data.taglines[0]);
        setTaglines2(data.taglines[1]);
        setTelegramLink(data.telegramLink || '');
      })
      .catch((error) => console.error('Error fetching top section data:', error));
  }, []);
  

  const navigate = useNavigate();

  const navigateToTelegram = () => {
     // Replace with your Telegram group link
    window.open(telegramLink, "_blank");
  };

  const navigateToContact = () => {
    handleNavigation("Contact Us", "/contact");
  };

 

  return (
    <section
      className="hero"
      id="home"
      role="region"
      aria-labelledby="hero-title"
    >
      <video
        className="videoBg"
        src="/video/2.mp4"
        autoPlay
        loop
        muted
        playsInline
      />
      <div className="hero-content">
        <h1 id="hero-title">
          {taglines1}
        </h1>
        <p>{taglines2}</p>
        <div className="hero-buttons">
          <button
            className="cta-primary glow-class"
            aria-label="Start Trading Journey"
            onClick={navigateToContact}
          >
            Start Your Trading Journey Today
          </button>
          <button
            className="cta-secondary telegram-button glow-class"
            aria-label="Join Telegram"
            onClick={navigateToTelegram}
          >
            <FaTelegramPlane style={{ marginRight: "8px" }} />
            Join Telegram
          </button>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
