import React from "react";
import styles from "../styles/components/TeamMemberHomeLayout.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
const TeamMemberHomeLayout = ({ teamMembers }) => {
  return (
    <>
      <section className="overview" id="services" role="region" aria-labelledby="overview-title">
        <h2 id="overview-title">PROFESSIONAL TRADERS IN OUR COMMUNITY?</h2>
        
      </section>
 
      <div className={styles.carouselContainer}>
        <Swiper
          spaceBetween={20}
          slidesPerView={5}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          breakpoints={{
            220: { slidesPerView: 1 },
            320: { slidesPerView: 1 }, // Extra small devices
            480: { slidesPerView: 2 }, // Mobile devices
            640: { slidesPerView: 3 }, // Small tablets
            768: { slidesPerView: 4 }, // Tablets
            1024: { slidesPerView: 5 }, // Desktops and larger devices
          }}
        >
          {teamMembers.map((member, index) => (
            <SwiperSlide key={index}>
              <div className={styles.teamMember}>
                <img
                  src={member.image}
                  alt={member.name}
                  className={styles.image}
                />
                <h3 className={styles.name}>{member.name}</h3>
                <p className={styles.experience}>{member.experience}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default TeamMemberHomeLayout;

