import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../styles/components/MobileLayout.module.css';
import "../styles/components/OverviewSection.css";
import {
  FaLightbulb,
  FaHandshake,
  FaChartLine,
  FaRocket,
  FaGlobe,
  FaBookOpen,
  FaBrain,
  FaUser,
  FaHeart,
  FaHome,
  FaCalendarAlt,
  FaBell,
  FaCar,
  FaCloud,
  FaCoffee,
  FaDesktop,
  FaFilm,
  FaMusic,
  FaShoppingCart,
  FaSearch,
  FaStar,
  FaTree,
  FaUtensils,
  FaWrench
} from 'react-icons/fa';

const icons = {
  FaLightbulb: <FaLightbulb />,
  FaHandshake: <FaHandshake />,
  FaChartLine: <FaChartLine />,
  FaRocket: <FaRocket />,
  FaGlobe: <FaGlobe />,
  FaBookOpen: <FaBookOpen />,
  FaBrain: <FaBrain />,
  FaUser: <FaUser />,
  FaHeart: <FaHeart />,
  FaHome: <FaHome />,
  FaCalendarAlt: <FaCalendarAlt />,
  FaBell: <FaBell />,
  FaCar: <FaCar />,
  FaCloud: <FaCloud />,
  FaCoffee: <FaCoffee />,
  FaDesktop: <FaDesktop />,
  FaFilm: <FaFilm />,
  FaMusic: <FaMusic />,
  FaShoppingCart: <FaShoppingCart />,
  FaSearch: <FaSearch />,
  FaStar: <FaStar />,
  FaTree: <FaTree />,
  FaUtensils: <FaUtensils />,
  FaWrench: <FaWrench />
};



const MobileLayout = () => {
  const [features, setFeatures] = useState([]);



  useEffect(() => {
    fetch('/fileData/homewhyChooseUsData.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setFeatures(data.featureCards);
        console.log("response :", data);
      })
      .catch(error => {
        console.error("Error fetching the features data:", error);
      });
  }, []);
  

  const FeatureCard = ({ icon, title, description, iconColor }) => (
    <div className={styles.featureCard} role="region">
      <i className="icon" style={{ color: iconColor }}>{icon}</i>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );

  return (
    <>
      <div className={styles.mobilelayoutScreenGradient}>
        <section className="overview mobilelayoutScreenGradient" id="services" role="region" aria-labelledby="overview-title">
          <h2 id="overview-title">WHY CHOOSE VITTAPATH TRADING SERVICES?</h2>
        </section>

        <div className={styles.container}>
          <div className={styles.mobileFrame}>
            <img
              src={require("../assets/photo/mobileframe2.png")}
              alt="Mobile Frame"
              className={styles.mobileframe2}
            />
            <video
              className={styles.video}
              src="/video/4.mp4"
              autoPlay
              loop
              muted
            ></video>
          </div>

          <div className={styles.servicesContainer}>
            <div className={styles.cardsContainer}>
              {features.map((feature, index) => (
                <div className={styles.card} key={index}>
                  <FeatureCard
                    icon={icons[feature.icon]}
                    title={feature.title}
                    description={feature.description}
                    iconColor={feature.iconColor}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileLayout;
