import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/pages/ContactUsPage.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const [contactDetails, setContactDetails] = useState(null);

  useEffect(() => {
    axios
      .get("/fileData/contactUsData.json")
      .then((response) => setContactDetails(response.data.details))
      .catch((error) => console.error("Error fetching contact details:", error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
  
    axios
      .post("/saveLeadData.php", formData)
      .then((response) => {
        if (response.data.status === "success") {
          alert("Form submitted successfully!");
          setFormData({
            name: "",
            email: "",
            number: "",
            message: "",
          });
        } else {
          alert("Failed to submit the form: " + response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        alert("An error occurred while submitting the form.");
      });
  };
  

  if (!contactDetails) return <p>Loading contact details...</p>;

  return (
    <>
      <Header activepage={"Contact Us"} />
      <div className="contact-us-page">
        <h2 className="contact-title">Contact Us</h2>
        <p className="contact-description">
          We're an online-only company, and we’re here to help you! Whether you have a question, suggestion, or need assistance, feel free to reach out to us.
        </p>

        <div className="contact-container">
          {/* Send Us a Message */}
          <div className="contact-form">
            <h3 className="form-title">Send Us a Message</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="number"
                  placeholder="Your Phone Number"
                  value={formData.number}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <textarea
                  name="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>
          </div>

          {/* How to Reach Us */}
          <div className="contact-info">
            <h3 className="info-title">How to Reach Us</h3>
            <div className="info-cards">
              <div className="info-card">
                <i className="icon fa fa-envelope"></i>
                <strong>Email:</strong>
                <p>{contactDetails.email}</p>
              </div>
              <div className="info-card">
                <i className="icon fa fa-phone"></i>
                <strong>Phone:</strong>
                <p>{contactDetails.phone}</p>
              </div>
              <div className="info-card">
                <i className="fab fa-whatsapp"></i>
                <strong>WhatsApp:</strong>
                <p>
                  <a
                    href={contactDetails.whatsapp}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Chat with us on WhatsApp
                  </a>
                </p>
              </div>
              <div className="info-card">
                <i className="fab fa-telegram"></i>
                <strong>Telegram:</strong>
                <p>
                  <a
                    href={contactDetails.telegram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Chat with us on Telegram
                  </a>
                </p>
              </div>
              <div className="info-card">
                <i className="icon fa fa-share-alt"></i>
                <strong>Social Media:</strong>
                <div className="social-media-links">
                  <a
                    href={contactDetails.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>{" "}
                  |
                  <a
                    href={contactDetails.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>{" "}
                  |
                  <a
                    href={contactDetails.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUsPage;
