import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/pages/Services.css";
import ServiceCard from "../components/ServiceCard";
import { FaChartLine, FaBook, FaCalendarAlt, FaComments, FaVideo } from "react-icons/fa";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Styles from "../styles/pages/services.module.css";

const icons = {
  FaChartLine: <FaChartLine />,
  FaBook: <FaBook />,
  FaCalendarAlt: <FaCalendarAlt />,
  FaComments: <FaComments />,
  FaVideo: <FaVideo />
};

const ServicePage = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch("/fileData/servicesData.json");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setServices(data.cards);
      } catch (error) {
        console.error("Error fetching services data:", error);
      }
    };
  
    fetchServices();
  }, []);
  

  return (
    <>
      <Header activepage={"service"} />
      <div className="service-page">
        <h2 className={Styles.pageTitleU}>Our Services</h2>
        <p className={Styles.pageDescriptionU}>
          Explore the various services we offer to help you excel in trading and market analysis.
        </p>
        <div className="service-cards">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              description={service.description}
              icon={icons[service.icon]} // Dynamically use the correct icon
              bgColor={service.bgColor}
              accentColor={service.accentColor}
              color={service.color}
              popupContent={service.popupContent}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ServicePage;
