import React, { useState } from 'react';

const ServiceCard = ({ title, description, icon, bgColor, accentColor, color, popupContent }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);


  return (
    <div
      className="service-card"
      style={{ backgroundColor: bgColor }}
    >
      <div className="icon" style={{ color: accentColor }}>
        {icon}
      </div>
      <h3 style={{ color: accentColor }}>{title}</h3>
      <p style={{ color }}>{description}</p>
      <button
        className="btn"
        style={{
          backgroundColor: accentColor,
          color: bgColor,
        }} 
        onClick={openPopup}
      >
        Learn More
      </button>

      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup">
            <button className="popup-close" onClick={closePopup}>
              &times;
            </button>
            <h3>{title}</h3>
            <p>{popupContent}</p>
            <a
              href="https://wa.me/?text=I%20want%20to%20know%20more%20about%20this%20service!"
              target="_blank"
              rel="noopener noreferrer"
              className="btn whatsapp-btn"
            >
              Inquire on WhatsApp
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceCard;
