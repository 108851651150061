import React, { useEffect, useState } from 'react';
import '../styles/pages/TestimonialPage.css';
import styles from '../styles/components/TestimonialsCardSection.module.css';
import { FaQuoteLeft } from 'react-icons/fa';
import { FaUserTie, FaUserAlt } from 'react-icons/fa'; // Icons for male and female
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

const TestimonialCard = ({ avatar, name, role, feedback, bgColor, textColor, gender }) => {
    return (
        <div
            className={styles.testimonialCard}
            style={{ backgroundColor: bgColor, color: textColor }}
        >
            <div className={styles.clientInfo}>
                {avatar ? (
                    <img src={avatar} alt={`${name}'s avatar`} className={styles.avatar} />
                ) : (
                    <div className={styles.iconAvatar}>
                        {gender === 'male' ? (
                            <FaUserTie className={styles.icon} />
                        ) : (
                            <FaUserAlt className={styles.icon} />
                        )}
                    </div>
                )}
                <h3 className={styles.clientName}>{name}</h3>
                <p className={styles.role}>{role}</p>
            </div>
            <br />
            <p className={styles.feedback}>"{feedback}"</p>
        </div>
    );
};

const TestimonialsCardSection = () => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        // Fetch the testimonials JSON
        fetch('/fileData/clientData.json')
            .then((response) => response.json())
            .then((data) => setTestimonials(data.clients))
            .catch((error) => console.error('Error fetching testimonials:', error));
    }, []);

    return (
        <div className={styles.testimonialPage}>
            <h2 className={styles.pageTitle}>WHAT OUR CLIENTS SAY</h2>
            <p className={styles.pageDescription}>
                Discover how our platform is making a difference in the lives of our users. Their words, their experiences!
            </p>
            <Swiper
                modules={[Pagination]}
                spaceBetween={20}
                slidesPerView={1}
                pagination={{
                    clickable: true,
                    el: `.${styles.customPagination}`, // Referencing CSS module class
                }}
                breakpoints={{
                    640: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                }}
            >
                {testimonials.map((testimonial, index) => (
                    <SwiperSlide key={index}>
                        <TestimonialCard
                            avatar={testimonial.avatar || null}
                            name={testimonial.name}
                            role={testimonial.role}
                            feedback={testimonial.feedback}
                            bgColor={testimonial.bgColor}
                            textColor={testimonial.textColor}
                            gender={testimonial.gender}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={styles.customPagination}></div>
        </div>
    );
};

export default TestimonialsCardSection;
