import React, { useState, useEffect } from "react";
import axios from "axios";
import '../styles/pages/SubscriptionPlanPage.css';
import { FaCheckCircle } from 'react-icons/fa';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Styles from "../styles/pages/services.module.css";

const SubscriptionCard = ({ title, price, features, bgColor, accentColor, isPopular }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="subscription-card" style={{ backgroundColor: bgColor }}>
      {isPopular && <div className="popular-badge">Most Popular</div>}
      <h3 style={{ color: "white" }}>{title}</h3>
      <p className="price">
        <span style={{ color: accentColor }}>${price}</span> / Month
      </p>
      <ul className="features-list">
        {features.map((feature, index) => (
          <li key={index}>
            <FaCheckCircle className="feature-icon" style={{ color: "white" }} />
            <span style={{ color: "white" }}>{feature}</span>
          </li>
        ))}
      </ul>
      <button
        className="btn"
        style={{
          backgroundColor: accentColor,
          color: bgColor === 'var(--white)' ? 'var(--dark-blue)' : 'white',
        }}
      >
        Subscribe Now
      </button>
    </div>
  );
};

const SubscriptionPlanPage = () => {
  const [plans, setPlans] = useState([]);
  const shades = ['#62a5eb', '#4a4a48', '#1c1c1b']; // You can adjust or fetch this dynamically if needed


  useEffect(() => {
    // Fetch the subscription plans from the JSON file in the public folder
    fetch('/fileData/subscriptionsData.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setPlans(data.cards); // Update state with the fetched plans
      })
      .catch((error) => {
        console.error("Error fetching subscription plans:", error);
      });
  }, []);
  

  return (
    <>
      <Header activepage={"Subscription"} />
      <div className="subscription-plan-page">
        <h2 className={Styles.pageTitleU}>Choose Your Perfect Plan</h2>
        <p className={Styles.pageDescriptionU}>
          Unlock the features you need to elevate your trading skills. Choose from our carefully crafted plans.
        </p>
        <div className="subscription-cards">
          {plans.length > 0 ? (
            plans.map((plan, index) => (
              <SubscriptionCard
                key={index}
                title={plan.title}
                price={plan.price}
                features={plan.features}
                bgColor={shades[index]} // Assign bgColor dynamically
                accentColor={plan.accentColor}
                isPopular={plan.isPopular}
              />
            ))
          ) : (
            <p>Loading plans...</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SubscriptionPlanPage;
